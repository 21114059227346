import { Moment } from "moment";
import { PhoneNumber } from "@hd2/common/types";
import { WritableComputedRef, Ref } from "vue";

export type WritableRef<TValue> = Ref<TValue> | WritableComputedRef<TValue>;

export type ContractType =
  | "INDIVIDUAL_PRACTITION"
  | "INDIVIDUAL_PRACTITION_ONLY_CALLED"
  | "INDIVIDUAL_PRACTITION_ONLY_STATIONARY"
  | "INDIVIDUAL_SPECIALISTIC_PRACTITION"
  | "INDIVIDUAL_SPECIALISTIC_PRACTITION_ONLY_CALLED"
  | "INDIVIDUAL_SPECIALISTIC_PRACTITION_ONLY_STATIONARY"
  | "GROUP_PRACTITION_AS_CIVIL_PARTNERSHIP"
  | "GROUP_PRACTITION_AS_GENERAL_PARTNERSHIP"
  | "GROUP_PRACTITION_AS_PROFESSIONAL_PARTNERSHIP";

export type VisitType = "HOUSE" | "CALL" | "CHAT" | "VIDEO" | "STATIONARY";
export type StatusReason =
  | "CANCELLED_BY_PATIENT"
  | "INCORRECT_DATA"
  | "SYSTEM_FAIL"
  | "NO_NFZ_INSURANCE";
export type VisitStatus =
  | "WAITING_FOR_PAYMENT"
  | "PAID"
  | "FINISHED"
  | "CANCELLED_BY_DOCTOR"
  | "FINISHED_NO_CONTACT"
  | "NOT_REALIZED";
export type FinalVisitStatus =
  | "CANCELLED_BY_DOCTOR"
  | "FINISHED"
  | "FINISHED_NO_CONTACT";

export type RegisterStatus =
  | "NO_ACCOUNT"
  | "PERSONAL"
  | "CONTRACT"
  | "SPECIALIZATIONS"
  | "WAITING_FOR_ACCEPT"
  | "FULLY_REGISTERED";

export type AvailabilityStatus = "PLANNED" | "ACCEPTED" | "REJECTED" | "NONE";

export type AureroVisitStatus = "INIT" | "IN_PROGRESS" | "FINISHED";

export type SpecializationsStatus = "IN_PROGRESS" | "DONE";

export interface RuntimeConfig {
  apiUrl: string;
  mockup: boolean;
  authUrl: string;
  authRealm: string;
  authClientId: string;
  maintenance?: boolean;
}

export interface VisitsFilterModel {
  date: Moment | null;
  pesel: string;
  selectedPatientId: Patient["id"] | undefined;
  visitTypes: Array<VisitType>;
  statuses: Array<VisitStatus>;
}

export interface BillingFilterModel {
  date: Moment[];
}

export type VisitsFilterData = Omit<VisitsFilterModel, "selectedPatientId">;

export interface Patient {
  id: number;
  firstName: string;
  lastName: string;
  pesel: string;
  phone: string;
}

export interface ResponseList<T> {
  content: Array<T>;
  totalElements: number;
  size: number;
  pageable: {
    pageNumber: number;
  };
}

export interface Visit {
  id: number;
  status: VisitStatus;
  from: string | null;
  to: string | null;
  preferredVisitLanguage?: string;
  countryOfResidence?: string;
  patients: Array<
    Patient & {
      aureroVisitId: number;
      aureroVisitStatus: AureroVisitStatus;
      symptoms: string | null;
    }
  >;
  details: {
    type: VisitType;
    contractorName: string;
    address?: {
      street: string;
      houseNumber: string;
      flatNumber?: string;
      postCode: string;
      city: string;
    };
    specialization: string;
    nfzVisit: boolean;
    nfzProductCode: string;
  };
  payment: {
    type: string;
    status: string;
    patientPrice: number;
  };
}

export interface RegisterContract {
  contractType: string | null;
  academicTitle: string;
  pwz: {
    file?: string;
    number: string;
    fileName?: string;
  };

  authorityForMedicalCerts: boolean;
  company?: {
    name: string;
    nip: string;
    address: {
      street: string;
      houseNumber: string;
      flatNumber?: string;
      postCode: string;
      city: string;
    };
  };
  insurance: {
    dateTo: string;
    dateFrom: string;
    amount: Currency;
    file?: string;
    fileName?: string;
  };
  specializations: Array<RegisterSpecializationSpecializations>;
}

export interface Currency {
  value: number;
  currency: string;
}

export interface RegisterPersonal {
  photo?: string;
  firstName: string;
  lastName: string;
  pesel: string;
  phone: Omit<PhoneNumber, "pattern">;
  nativeLanguageId: number;
  foreignLanguageIds: Array<number>;
  address: {
    home: {
      street: string;
      houseNumber: string;
      flatNumber?: string;
      postCode: string;
      city: string;
    };
    mailing?: {
      street: string;
      houseNumber: string;
      flatNumber?: string;
      postCode: string;
      city: string;
    };
    secondary?: {
      street: string;
      houseNumber: string;
      flatNumber?: string;
      postCode: string;
      city: string;
    };
  };
}

export interface Specializations {
  [key: string]: number;
}

export interface RegisterSpecializationSpecializations {
  id: Specializations[keyof Specializations];
  status: SpecializationsStatus;
}

export interface RegisterSpecialization {
  consultationIds: Array<number>;
  consultationTypes: Array<VisitType>;
}

export interface VisitExt extends Visit {
  tmpStatus: VisitStatus;
  isChangeStatusDisabled: boolean;
  details: Visit["details"];
}

export enum AvailabilityModalMode {
  New = "NEW",
  Edit = "EDIT",
}

export interface AvailabilityRange {
  from: string;
  to: string;
}

export interface Availability {
  id: number;
  range: AvailabilityRange;
  status: AvailabilityStatus;
  serviceExecutionType: VisitType[];
}

export interface EventDataModel {
  range: AvailabilityRange;
  serviceExecutionType: VisitType[];
  id?: string | number;
}

export type Errors = {
  [key: string]: Array<string>;
};

export type AcademicTitle =
  | "TECH"
  | "LIC"
  | "MGR"
  | "LEK"
  | "LEK_DEN"
  | "DR_N_MED"
  | "DR_HAB_N_MED"
  | "PROF";

export interface Profile {
  firstName: string;
  lastName: string;
  academicTitle: AcademicTitle;
  email: string;
  showCertificate: boolean;
}

export interface UserState {
  id: string;
  firstName: string;
  lastName: string;
  academicTitle: AcademicTitle | "";
  email: string;
  registerStatus: RegisterStatus | "";
  showCertificate: boolean;
}

export interface Langs {
  [key: string]: number;
}

export interface ChooseVisitModal {
  visible: boolean;
  data?: VisitExt;
  paymentType: string;
  redirectUrl: string;
}

export type VisitTypeExt =
  | (VisitType & "CALL_SPECIALIST")
  | "VIDEO_SPECIALIST"
  | "CHAT_SPECIALIST"
  | "STATIONARY_SPECIALIST";

export type VisitMode = "DAY" | "WEEKEND_DAY" | "HOLIDAY";

export interface Billing {
  [key: string]: {
    [key: string]: {
      [key: string]: number;
    };
  };
}

export interface BillingObject {
  [key: string]: Array<BillingTable>;
}
export interface BillingTable extends BillingTableRecord {
  children: Array<BillingTableRecord>;
}

export interface BillingTableRecord {
  billingType: VisitTypeExt | VisitMode;
  visitAmount: number;
}

export interface ProductCode {
  code: string;
  description: string;
}

export interface NfzStatus {
  status: string;
  confirmedByPatient: boolean;
}

export type ConversationStatus = "ANSWERED" | "UNANSWERED";

export interface Conversations {
  patientFullName: string;
  status: ConversationStatus;
  visitDate: string;
  questionDate: string;
  conversationId: number;
}

export interface ConversationItem {
  role: string;
  senderName: string;
  message: string;
  deliveredOn: string;
}

export interface Conversation {
  status: ConversationStatus;
  visitDate: string;
  messages: Array<ConversationItem>;
}
