
import { defineComponent, ref, Ref, onMounted, reactive, inject } from "vue";
import { useI18n } from "vue-i18n";
import moment, { Moment } from "moment";
import AvailabilityFilters from "../components/AvailabilityFilters.vue";
import AvailabilityModal from "../components/AvailabilityModal.vue";
import {
  Availability,
  AvailabilityModalMode,
  EventDataModel,
} from "../../types";
import { AxiosStatic } from "axios";
import FullCalendar, {
  CalendarOptions,
  DayHeaderContentArg,
} from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import plLocale from "@fullcalendar/core/locales/pl";
import { visitTypesIconMap } from "../utils/const";
import { useShowErrorByCode } from "@hd2/common/src/composable/useShowErrorByCode";

interface CalendarInterface {
  plugins: unknown[];
  headerToolbar: boolean;
  locales: unknown[];
  locale: string;
  initialView: string;
  slotDuration: string;
  slotMaxTime: string;
  slotMinTime: string;
  slotLabelInterval: unknown;
  allDaySlot: boolean;
  events: unknown[];
}

interface AvailabilityModalModel {
  mode: AvailabilityModalMode | undefined;
  visible: boolean;
  event: EventDataModel | undefined;
}

export const AvailabilityComponent = defineComponent({
  components: {
    FullCalendar,
    AvailabilityFilters,
    AvailabilityModal,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const calendarRef = ref();
    const isEditModeDisabled: Ref<boolean> = ref(true);
    const isEditingDisabled: Ref<boolean> = ref(false);
    const availabilityFiltersFrom: Ref<Moment> = ref(moment());
    const showSpinner: Ref<boolean> = ref(false);
    const { showErrorByCode } = useShowErrorByCode();

    const availabilityModalModel: Ref<AvailabilityModalModel> = ref({
      mode: undefined,
      visible: false,
      event: undefined,
    });

    const calendarOptions: CalendarInterface = reactive({
      plugins: [timeGridPlugin, interactionPlugin],
      headerToolbar: false,
      locales: [plLocale],
      locale: "pl",
      height: "600px",
      initialView: "timeGridWeek",
      slotDuration: "00:15:00",
      slotMaxTime: "24:00:00",
      slotMinTime: "00:00:00",
      scrollTime: "06:00:00",
      slotLabelInterval: { hours: 1 },
      allDaySlot: false,
      events: [],
      dayHeaderContent: (args: DayHeaderContentArg) => {
        return moment(args.date).format("ddd. DD.MM");
      },
    });

    const availabilityCalendar: Ref<CalendarOptions | undefined> =
      ref(undefined);

    const getCalendar = async (from: Moment) => {
      showSpinner.value = true;
      isEditModeDisabled.value = true;
      try {
        calendarOptions.events = [];
        const calendarRes = await http
          .get(`/v1/doctors/availabilities?from=${from.format("YYYY-MM-DD")}`)
          .then((res) => {
            return res.data;
          });

        calendarRes.forEach((event: Availability) => {
          const tmpEvent = {
            id: event.id,
            serviceExecutionType: event.serviceExecutionType,
            classNames: [
              "availability-calendar__event",
              `availability-calendar__event--${event.status
                .toString()
                .toLowerCase()}`,
            ],
            status: event.status,
            start: event.range.from,
            end: event.range.to,
            display: "block",
            past: false,
          };
          if (new Date(event.range.to) < new Date()) {
            tmpEvent.classNames.push("availability-calendar__event--past");
            tmpEvent.past = true;
          }
          calendarOptions.events.push(tmpEvent as never);
        });
      } catch (err: unknown) {
        showErrorByCode(err);
      } finally {
        isEditModeDisabled.value = false;
        showSpinner.value = false;
      }
    };

    const changeDates = async (from: Moment) => {
      availabilityFiltersFrom.value = from;
      const calendarApi = calendarRef.value.getApi();
      calendarApi.gotoDate(from.format("YYYY-MM-DD"));
      await getCalendar(availabilityFiltersFrom.value);
    };

    const openEditModal = (
      mode: AvailabilityModalMode,
      event: EventDataModel
    ) => {
      availabilityModalModel.value.visible = true;
      availabilityModalModel.value.mode = mode;
      availabilityModalModel.value.event = event;
    };

    const afterAvailabilityUpdateSuccess = async () => {
      await getCalendar(availabilityFiltersFrom.value);
    };

    const clearAvailabilityModalModel = () => {
      availabilityModalModel.value.mode = undefined;
      availabilityModalModel.value.event = undefined;
    };

    onMounted(async () => {
      await getCalendar(availabilityFiltersFrom.value);
    });
    return {
      t,
      moment,
      availabilityModalModel,
      calendarOptions,
      availabilityCalendar,
      changeDates,
      calendarRef,
      visitTypesIconMap,
      showSpinner,
      isEditModeDisabled,
      isEditingDisabled,
      openEditModal,
      AvailabilityModalMode,
      afterAvailabilityUpdateSuccess,
      clearAvailabilityModalModel,
    };
  },
});
export default AvailabilityComponent;
