import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73e665dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-availabilit__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_picker = _resolveComponent("calendar-picker")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "availability-modal",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
    closable: false,
    maskClosable: false,
    title: 
      _ctx.mode == _ctx.AvailabilityModalMode.New
        ? _ctx.t('AVAILABILITY.AVAILABILITY_MODAL.ADD')
        : _ctx.t('AVAILABILITY.AVAILABILITY_MODAL.EDIT')
    ,
    keyboard: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        class: "availability-modal__submit",
        loading: _ctx.actionLoading,
        onClick: _ctx.submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("AVAILABILITY.AVAILABILITY_MODAL.SUBMIT")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "onClick"]),
      _createVNode(_component_a_button, {
        key: "back",
        onClick: _ctx.handleClose,
        loading: _ctx.actionLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("AVAILABILITY.AVAILABILITY_MODAL.CANCEL")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick", "loading"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_row, { gutter: [16, 16] }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              lg: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_calendar_picker, {
                  value: _ctx.model.dates,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.dates) = $event)),
                  disabledDate: _ctx.disabledRange,
                  single: _ctx.mode == _ctx.AvailabilityModalMode.Edit ? true : false,
                  class: _normalizeClass([_ctx.calendarError ? 'calendar-error' : '']),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.calendarError && (_ctx.calendarError = !_ctx.calendarError)))
                }, null, 8 /* PROPS */, ["value", "disabledDate", "single", "class"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_a_col, {
              lg: 12,
              xs: 24
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, { layout: "vertical" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, { gutter: [16, 16] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          lg: 12,
                          xs: 24
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: _ctx.t('AVAILABILITY.AVAILABILITY_MODAL.INTERVAL_FROM'),
                              class: "availability-modal__intervals-from"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  style: {"width":"100%"},
                                  value: _ctx.model.range.from,
                                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.range.from) = $event)),
                                  disabled: _ctx.actionLoading
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayliIntervals, (interval) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: interval,
                                        value: interval
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(interval), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["value", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: 12,
                          xs: 24
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: _ctx.t('AVAILABILITY.AVAILABILITY_MODAL.INTERVAL_TO'),
                              class: "availability-modal__intervals-to"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  style: {"width":"100%"},
                                  value: _ctx.model.range.to,
                                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.range.to) = $event)),
                                  disabled: _ctx.actionLoading
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dayliIntervals, (interval) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: interval,
                                        value: interval
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(interval), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["value", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_a_col, {
                          lg: 24,
                          xs: 24
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, _mergeProps({
                              label: 
                    _ctx.t('AVAILABILITY.AVAILABILITY_MODAL.SERVICE_EXECUTION_TYPE')
                  ,
                              class: "availability-modal__service-execution-type"
                            }, _ctx.validateInfos['serviceExecutionType']), {
                              default: _withCtx(() => [
                                _createVNode(_component_a_tree_select, {
                                  value: _ctx.model.serviceExecutionType,
                                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.serviceExecutionType) = $event)),
                                  style: {"width":"100%"},
                                  dropdownMatchSelectWidth: true,
                                  "tree-data": _ctx.visitTypes,
                                  treeNodeFilterProp: "title",
                                  treeNodeLabelProp: "title",
                                  "tree-checkable": "",
                                  disabled: _ctx.actionLoading
                                }, null, 8 /* PROPS */, ["value", "tree-data", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 16 /* FULL_PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["visible", "title"]))
}