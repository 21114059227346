
import { defineComponent, ref, watch } from "vue";
import { Calendar } from "ant-design-vue";
import moment, { Moment } from "moment";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons-vue";
import { getDate } from "../utils";

export const CalendarPickerComponent = defineComponent({
  name: "CalendarPicker",
  props: {
    value: {
      type: Array as PropType<Array<Moment>>,
      required: true,
      default: () => [],
    },
    disabledDate: {
      type: Function,
      default: (current: Moment) => {
        return current && current <= moment().startOf("day");
      },
    },
    single: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  components: {
    DoubleRightOutlined,
    DoubleLeftOutlined,
    ACalendar: Calendar,
  },
  setup(props, { emit }) {
    const defaultValue = ref<Moment>();
    const selectedDates = ref<Moment[]>(props.value);

    const onDateSelect = (selectedDay: Moment) => {
      if (props.single) {
        selectedDates.value = [];
        selectedDates.value.push(selectedDay);
      } else {
        if (
          selectedDates.value.some(
            (moment) => getDate(moment) === getDate(selectedDay)
          )
        ) {
          selectedDates.value = selectedDates.value.filter(
            (moment) => getDate(moment) !== getDate(selectedDay)
          );
        } else {
          selectedDates.value.push(selectedDay);
        }
      }

      emit("update:value", selectedDates.value);
    };

    const getMonths = (value: Moment) => {
      const current = value.clone();
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }
      return months;
    };

    const getYears = (value: Moment) => {
      const year = value.year();
      const years = [];
      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }
      return years;
    };

    watch(
      () => props.value,
      (nv) => {
        selectedDates.value = nv;
      }
    );

    return {
      defaultValue,
      getDate,
      getMonths,
      getYears,
      onDateSelect,
      selectedDates,
    };
  },
});

export default CalendarPickerComponent;
