import { notification } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import axios, { AxiosError } from "axios";

interface ExtendedAxiosResponse extends AxiosError {
  response: AxiosError["response"] & {
    version: string;
    detailsSlug: string;
  };
}

export const useShowErrorByCode = () => {
  const { t } = useI18n();
  const showErrorByCode = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        notification.open({
          message: t(`ERROR.500`),
          class: "error",
        });
        return "500";
      } else {
        if (
          (error as ExtendedAxiosResponse).response?.data.version &&
          (error as ExtendedAxiosResponse).response?.data.version === "v2"
        ) {
          if (
            (error as ExtendedAxiosResponse).response.data.fieldErrors &&
            (error as ExtendedAxiosResponse).response.data?.fieldErrors[0]?.slug
          ) {
            const fieldErrors = (error as ExtendedAxiosResponse).response.data
              .fieldErrors;
            const fieldError = fieldErrors[0];
            const slug = fieldError.slug;

            notification.open({
              message: t(`ERROR.${slug}`),
              class: "error",
            });
            return slug;
          } else {
            notification.open({
              message: t(`ERROR.${error.response?.data.slug}`),
              class: "error",
            });
          }
          return error.response?.data.slug;
        } else {
          notification.open({
            message: t(`ERROR.${error.response?.data.title}`),
            class: "error",
          });
          return error.response?.data.title;
        }
      }
    } else {
      notification.open({
        message: t(`ERROR.500`),
        class: "error",
      });
      return "500";
    }
  };
  return { showErrorByCode };
};
