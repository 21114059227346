
import {
  defineComponent,
  ref,
  Ref,
  inject,
  watch,
  reactive,
  computed,
  PropType,
} from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import CalendarPickerComponent from "@hd2/common/src/components/CalendarPicker.vue";
import moment, { Moment } from "moment";
import {
  VisitType,
  AvailabilityModalMode,
  AvailabilityRange,
  EventDataModel,
} from "../../types";
import { useShowErrorByCode } from "@hd2/common/src/composable/useShowErrorByCode";
import { Form, notification } from "ant-design-vue";
const useForm = Form.useForm;

interface Model {
  dates: Moment[];
  range: {
    from: string;
    to: string;
  };
  serviceExecutionType: VisitType[] | undefined;
}

interface Payload {
  range: AvailabilityRange;
  ranges: AvailabilityRange[];
  serviceExecutionType: VisitType[];
}

interface TreeDataItem {
  value: VisitType;
  key: string;
  title: string;
  disabled: string | boolean | undefined;
}

export const AvailabilityModal = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String as PropType<AvailabilityModalMode>,
      default: AvailabilityModalMode.New,
    },
    event: {
      type: Object as PropType<EventDataModel>,
      default: () => {
        return {};
      },
    },
  },
  components: {
    CalendarPicker: CalendarPickerComponent,
  },
  emits: ["after-confirm-success", "update:modelValue", "after-close"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrorByCode } = useShowErrorByCode();
    const calendarError = ref(false);
    const visible: Ref<boolean> = ref(false);

    const actionLoading: Ref<boolean> = ref(false);
    const model: Ref<Model> = ref({
      dates: [],
      range: {
        from: moment().startOf("day").format("HH:mm"),
        to: moment().startOf("day").format("HH:mm"),
      },
      serviceExecutionType: [],
    });

    const disableIfTeleVisits = computed(() => {
      if (
        model.value.serviceExecutionType &&
        model.value.serviceExecutionType.length > 0
      ) {
        return model.value.serviceExecutionType?.find((type) => {
          return (
            type == "CALL" ||
            type == "STATIONARY" ||
            type == "CHAT" ||
            type == "VIDEO"
          );
        });
      }
      return false;
    });

    const disableIfHouseVisits = computed(() => {
      if (
        model.value.serviceExecutionType &&
        model.value.serviceExecutionType.length > 0
      ) {
        return model.value.serviceExecutionType?.find((type) => {
          return type == "HOUSE";
        });
      }
      return false;
    });

    const visitTypes: Ref<Array<TreeDataItem>> = ref([
      {
        title: t(`VISIT_TYPE.CALL`),
        value: "CALL" as VisitType,
        key: "CALL",
        disabled: disableIfHouseVisits,
      },
      {
        title: t(`VISIT_TYPE.HOUSE`),
        value: "HOUSE" as VisitType,
        key: "HOUSE",
        disabled: disableIfTeleVisits,
      },
      {
        title: t(`VISIT_TYPE.STATIONARY`),
        value: "STATIONARY" as VisitType,
        key: "STATIONARY",
        disabled: disableIfHouseVisits,
      },
      {
        title: t(`VISIT_TYPE.CHAT`),
        value: "CHAT" as VisitType,
        key: "CHAT",
        disabled: disableIfHouseVisits,
      },
      {
        title: t(`VISIT_TYPE.VIDEO`),
        value: "VIDEO" as VisitType,
        key: "VIDEO",
        disabled: disableIfHouseVisits,
      },
    ]);

    const rules = reactive({
      ["serviceExecutionType"]: [
        {
          required: true,
          message: t("AVAILABILITY.AVAILABILITY_MODAL.FIELD_REQUIRED"),
        },
      ],
    });

    const {
      resetFields: resetFields,
      validate: validate,
      validateInfos: validateInfos,
    } = useForm(model, rules);

    const generateDayliIntervals = () => {
      const timeArray: Array<string> = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let quarter = 0; quarter < 4; quarter++) {
          timeArray.push(
            moment({ hour: hour, minute: quarter * 15 }).format("HH:mm")
          );
        }
      }
      return timeArray;
    };
    const dayliIntervals: Ref<Array<string>> = ref(generateDayliIntervals());

    const setupDateRanges = (ranges: AvailabilityRange, date: Moment) => {
      return {
        from:
          date.format("YYYY-MM-DD") +
          moment(ranges.from, "HH:mm").format("THH:mm"),
        to:
          ranges.to == "00:00"
            ? moment(date).add(1, "days").format("YYYY-MM-DD") +
              moment(ranges.to, "HH:mm").format("THH:mm")
            : date.format("YYYY-MM-DD") +
              moment(ranges.to, "HH:mm").format("THH:mm"),
      };
    };

    const handleClose = () => {
      resetFields();
      calendarError.value = false;
      emit("after-close");
      emit("update:modelValue", false);
    };

    const submit = async () => {
      if (model.value.dates.length > 0) {
        validate()
          .then(async () => {
            actionLoading.value = true;

            const payload: Partial<Payload> = {
              serviceExecutionType: model.value.serviceExecutionType,
            };

            try {
              if (props.mode == AvailabilityModalMode.Edit) {
                payload.range = setupDateRanges(
                  model.value.range,
                  model.value.dates[0]
                );
                await http.put(
                  `v1/doctors/availabilities/${props.event.id}`,
                  payload
                );
              }
              if (props.mode == AvailabilityModalMode.New) {
                payload.ranges = model.value.dates.map((date) => {
                  return setupDateRanges(model.value.range, date);
                });
                await http.post("v1/doctors/availabilities", payload);
              }

              notification.open({
                message: t("AVAILABILITY.AVAILABILITY_MODAL.CHANGED"),
                class: "success",
              });
              emit("after-confirm-success");
              handleClose();
            } catch (err: unknown) {
              const slug = showErrorByCode(err);
              if (slug == "AVAILABILITY_DOESNT_EXIST_REFRESH_VIEW") {
                emit("after-confirm-success");
                handleClose();
              }
            } finally {
              actionLoading.value = false;
            }
          })
          .catch(() => {
            notification.open({
              message: t(`ERROR.FE.1002`),
              class: "error",
            });
          });
      } else {
        notification.open({
          message: t(`ERROR.FE.1002`),
          class: "error",
        });
        calendarError.value = true;
      }
    };

    const disabledRange = (current: Moment) => {
      return current && current <= moment().startOf("day");
    };

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
        if (visible.value && props.mode == AvailabilityModalMode.Edit) {
          model.value.dates.push(moment(props.event.range.from));
          model.value.range.from = moment(props.event.range.from).format(
            "HH:mm"
          );
          model.value.range.to = moment(props.event.range.to).format("HH:mm");
          model.value.serviceExecutionType = props.event.serviceExecutionType;
        }
      }
    );

    return {
      actionLoading,
      submit,
      handleClose,
      model,
      visible,
      t,
      disabledRange,
      dayliIntervals,
      visitTypes,
      AvailabilityModalMode,
      calendarError,
      validateInfos,
    };
  },
});

export default AvailabilityModal;
