import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd28f4b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar" }
const _hoisted_2 = { class: "calendar__wrapper" }
const _hoisted_3 = { class: "calendar__header" }
const _hoisted_4 = {
  key: 0,
  class: "calendar__day--selected"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoubleLeftOutlined = _resolveComponent("DoubleLeftOutlined")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_DoubleRightOutlined = _resolveComponent("DoubleRightOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_calendar = _resolveComponent("a-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_calendar, {
        value: _ctx.defaultValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.defaultValue) = $event)),
        fullscreen: false,
        "disabled-date": _ctx.disabledDate,
        onSelect: _ctx.onDateSelect
      }, {
        headerRender: _withCtx(({ value, onChange }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_row, {
              type: "flex",
              justify: "space-between",
              align: "middle"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DoubleLeftOutlined, {
                      class: "prev-month",
                      onClick: 
                    () =>
                      onChange(
                        value.clone().month(parseInt(value.month(), 10) - 1)
                      )
                  
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_a_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      class: "month-select",
                      size: "small",
                      "dropdown-match-select-width": false,
                      value: String(value.month()),
                      onChange: 
                    (selectedMonth) => {
                      onChange(
                        value.clone().month(parseInt(selectedMonth, 10))
                      );
                    }
                  
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMonths(value), (val, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            class: "month-item",
                            key: String(index)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(val), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onChange"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_a_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      class: "my-year-select",
                      size: "small",
                      "dropdown-match-select-width": false,
                      value: String(value.year()),
                      onChange: 
                    (newYear) => {
                      onChange(value.clone().year(newYear));
                    }
                  
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getYears(value), (val) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            class: "year-item",
                            key: String(val)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(val), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onChange"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                _createVNode(_component_a_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DoubleRightOutlined, {
                      class: "next-month",
                      onClick: 
                    () =>
                      onChange(
                        value.clone().month(parseInt(value.month(), 10) + 1)
                      )
                  
                    }, null, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ])
        ]),
        dateCellRender: _withCtx(({ current: value }) => [
          (
              _ctx.selectedDates.some((moment) => _ctx.getDate(moment) === _ctx.getDate(value))
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(value.format("DD")), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value", "disabled-date", "onSelect"])
    ])
  ]))
}