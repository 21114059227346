import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65fde115"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "availability" }
const _hoisted_2 = { class: "availability-calendar__wrapper" }
const _hoisted_3 = { class: "availability-calendar__spinner" }
const _hoisted_4 = { class: "availability-calendar__content" }
const _hoisted_5 = { class: "availability-calendar__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_availability_filters = _resolveComponent("availability-filters")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_full_calendar = _resolveComponent("full-calendar")!
  const _component_availability_modal = _resolveComponent("availability-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.t("AVAILABILITY.HEADER")), 1 /* TEXT */),
      _createVNode(_component_availability_filters, {
        class: "availability-filters",
        onChange: _ctx.changeDates,
        onOpenEditModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditModal(_ctx.AvailabilityModalMode.New))),
        onAfterCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeEditModal())),
        isEditModeDisabled: _ctx.isEditModeDisabled
      }, null, 8 /* PROPS */, ["onChange", "isEditModeDisabled"]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_spin, { size: "large" })
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.showSpinner]
        ]),
        _createVNode(_component_full_calendar, {
          options: _ctx.calendarOptions,
          ref: "calendarRef",
          class: "availability-calendar"
        }, {
          eventContent: _withCtx((arg) => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.moment(arg.event.start).format("HH:mm")) + " - " + _toDisplayString(_ctx.moment(arg.event.end).format("HH:mm")), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(arg.event.extendedProps
                    .serviceExecutionType, (visitType) => {
                    return (_openBlock(), _createElementBlock("span", {
                      class: _normalizeClass([
                    'anticon',
                    `availability-calendar__icon--${visitType.toLowerCase()}`,
                  ]),
                      key: visitType
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.visitTypesIconMap.get(visitType))))
                    ], 2 /* CLASS */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              (
                !arg.event.extendedProps.past &&
                arg.event.extendedProps.status == 'PLANNED'
              )
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    class: "availability-calendar__cta",
                    onClick: ($event: any) => (
                _ctx.openEditModal(_ctx.AvailabilityModalMode.Edit, {
                  range: {
                    from: _ctx.moment(arg.event.start).format('YYYY-MM-DDTHH:mm'),
                    to: _ctx.moment(arg.event.end).format('YYYY-MM-DDTHH:mm'),
                  },
                  serviceExecutionType:
                    arg.event.extendedProps.serviceExecutionType,
                  id: arg.event.id,
                })
              )
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("AVAILABILITY.AVAILABILITY_CALENDAR.EDIT")), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["options"])
      ])
    ]),
    _createVNode(_component_availability_modal, {
      modelValue: _ctx.availabilityModalModel.visible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.availabilityModalModel.visible) = $event)),
      mode: _ctx.availabilityModalModel.mode,
      event: _ctx.availabilityModalModel.event,
      onAfterConfirmSuccess: _ctx.afterAvailabilityUpdateSuccess,
      onAfterClose: _ctx.clearAvailabilityModalModel
    }, null, 8 /* PROPS */, ["modelValue", "mode", "event", "onAfterConfirmSuccess", "onAfterClose"])
  ], 64 /* STABLE_FRAGMENT */))
}