
import { defineComponent, ref, Ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";

import moment from "moment";
import {
  LeftOutlined,
  RightOutlined,
  CalendarOutlined,
  CaretDownOutlined,
} from "@ant-design/icons-vue";

interface GetAvailabilitiesPayload {
  from: moment.Moment | null;
  to: moment.Moment | null;
}

export const AvailabilityFiltersComponent = defineComponent({
  emits: ["change", "openEditModal", "afterCancel"],
  props: {
    isEditModeDisabled: {
      Boolean,
      default: false,
    },
  },
  components: {
    RightOutlined,
    LeftOutlined,
    CalendarOutlined,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const date: Ref<moment.Moment> = ref(moment());
    const dateOutput = computed(
      () =>
        `${moment(date.value).startOf("week").format("DD.MM.YYYY")} - ${moment(
          date.value
        )
          .endOf("week")
          .format("DD.MM.YYYY")}`
    );

    const getAvailabilitiesPayload: GetAvailabilitiesPayload = reactive({
      from: moment().startOf("week"),
      to: moment().endOf("week"),
      availabilitiesLoaded: false,
    });

    const changeDate = (newDate: string | moment.Moment): void => {
      date.value = moment(newDate);
      const chosenDate = moment(newDate);

      const from = moment(chosenDate.startOf("week"));
      const to = moment(chosenDate.endOf("week"));

      getAvailabilitiesPayload.from = moment(from);
      getAvailabilitiesPayload.to = moment(to);

      emit("change", getAvailabilitiesPayload.from);
    };

    const nextWeek = (): void => {
      const newDate = moment(date.value.add(7, "days"));
      date.value = newDate;
      changeDate(newDate);
    };

    const previousWeek = (): void => {
      const newDate = moment(date.value.subtract(7, "days"));
      date.value = newDate;
      changeDate(newDate);
    };

    const openEditModal = () => {
      emit("openEditModal");
    };

    return {
      t,
      changeDate,
      date,
      previousWeek,
      nextWeek,
      LeftOutlined,
      RightOutlined,
      CaretDownOutlined,
      getAvailabilitiesPayload,
      dateOutput,
      CalendarOutlined,
      openEditModal,
    };
  },
});
export default AvailabilityFiltersComponent;
